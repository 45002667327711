import * as Sentry from '@sentry/react';
export const postMessageWithAwait = async (body, responseTypes) => new Promise((resolve, reject) => {
    const handleResponse = (message) => {
        try {
            const type = responseTypes ?? body.type;
            const result = JSON.parse(message.data);
            if (!checkResponseType(type, result.type)) return;
            window.removeEventListener('message', handleResponse, true);
            if (result?.error) {
                Sentry.captureException(result);
                reject(null);
            }
            resolve(result);
        } catch (err) {
            console.warn('App message failed to parse');
        }
    }
    window.addEventListener('message', handleResponse, true);
    window.ReactNativeWebView?.postMessage(JSON.stringify(body));
});

const checkResponseType = (type, resultType) => type === resultType || type.length && type.includes(resultType);