import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router';
import { hasCustomerDetails, existsButNeedsMobileNumber } from './AppController';
import BasketController from './features/basket/BasketController';
import CheckoutController from './features/checkout/CheckoutController';
import MenuController from './features/menu/MenuController';
import PaymentController from './features/payment/PaymentController';
import PasswordResetConfirmation from './features/checkout/pages/PasswordResetConfirmation';
import OrderSummary from './features/payment/pages/OrderSummary';
import ForgottenPassword from './features/checkout/pages/ForgottenPassword';
import {
  useAddBundleToBasketMutation, useAddToBasketMutation, useCheckOutOfStockMutation, useQuickAddItemAndRewardMutation, useQuickAddItemMutation,
  useQuickRemoveItemMutation, useQuickSelectItemMutation, useRemoveBundleFromBasketMutation, useRemoveFromBasketMutation
} from './services/basket.api';
import { applyDefaultSubProducts } from './helpers/basketHelpers';
import { historyMW } from './helpers/routingHelpers';
import { DeliveryTimeUpdateModalWrapper } from './helpers/modalHelpers';
import { setUpdatedDeliveryTime } from './store/sessionSlice';
import hash from 'object-hash';
import { useLazyGetOrderForTodayQuery } from './services/order.api';
import LoaderBlack from '../src/img/common/Loader_360x360.gif';

const OrderJourneyController = ({ setShowChangeRestaurantModal, refreshBasket, setDisablePaymentBackButton, disablePaymentBackButton }) => {
  const { path } = useRouteMatch();
  const { customer, restaurant, inApp, collectionTime, deliveryTimeUpdated } = useSelector(state => state.session);
  const basket = useSelector(state => state.basket);
  const dispatch = useDispatch();
  const [removeFromBasket, { isLoading: isRemovingFromBasket }] = useRemoveFromBasketMutation();
  const [addToBasket, { isLoading: isAddingToBasket }] = useAddToBasketMutation();
  const [quickAddItemAndReward, { isLoading: isQuickAddingToBasket }] = useQuickAddItemAndRewardMutation();
  const [quickAddItem] = useQuickAddItemMutation();
  const [quickRemoveItem] = useQuickRemoveItemMutation();
  const [checkOutOfStock] = useCheckOutOfStockMutation();
  const [addBundleToBasket, { isLoading: isAddingBundleToBasket }] = useAddBundleToBasketMutation();
  const [removeBundleFromBasket, { isLoading: isRemovingBundleFromBasket }] = useRemoveBundleFromBasketMutation();
  const [quickSelectItemMutation, { isLoading: isQuickSelectingItem }] = useQuickSelectItemMutation();
  const [getOrder, { data, isLoading: isOrderLoading, isFetching, isUninitialized }] = useLazyGetOrderForTodayQuery();
  const appApproved = useSelector(state => state.session?.customer?.appApproved);
  const loyalty = useSelector(state => state.session?.features.loyalty) && appApproved;
  const history = useHistory();
  const isUpdatingBasket =
  isRemovingFromBasket ||
  isAddingToBasket ||
  isAddingBundleToBasket ||
  isRemovingBundleFromBasket ||
  isQuickAddingToBasket ||
  isQuickSelectingItem;
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const [notification, setNotification] = useState({ visible: false, item: {} });

  const handleRemoveItem = async (item) => {
    if (!isUpdatingBasket) {
      let response;
      const body = { ...item, quantity: 1, restaurantId: restaurant?.id };
      if (item.eposDiscountId) {
        response = await removeBundleFromBasket(body);
      } else {
        response = await removeFromBasket([body]);
      }
      if (response.data.status === 'OK') {
        refreshBasket();
      }
    }
  };

  const handleAddItem = async (item, custom) => {
    if (!isUpdatingBasket) {
      let response;
      item = applyDefaultSubProducts(item);
      if (custom) {
        const customID = hash(item);
        item = { ...item, customID: customID };
      }
      const body = { ...item, quantity: 1, restaurantId: restaurant?.id };
      if (item.eposDiscountId) {
        response = await addBundleToBasket(body);
      } else {
        response = await addToBasket([body]);
      }
      if (response?.data?.status === 'OK') {
        setNotification({ visible: true, item });
        refreshBasket();
      }
    }
  };

  const handleQuickAddItemAndReward = async (productId, portionTypeId, thirdPartyRewardTypeId) => {

    let response = await quickAddItemAndReward({
      productId,
      portionTypeId,
      thirdPartyRewardTypeId
    });

    if (response.data) {
      await handleAddItem(response.data);
      await quickSelectItemMutation({
        productId,
        portionTypeId,
        thirdPartyRewardTypeId
      });
    }
  };

  window.quickAdd = (productId, portionId) => quickAddItem({ productId, portionId });
  window.quickRemove = (productId, portionId) => quickRemoveItem({ productId, portionId });
  window.quickRoute = (path, isReplace) => isReplace ? historyMW.replace(path, basket.isDelivery, history) : historyMW.push(path, basket.isDelivery, history);
  window.getBasket = () => ({ items: basket.items, bundles: basket.bundles });
  window.checkOutOfStock = async (productIds) => await checkOutOfStock(productIds);

  const handleUpdatedDeliveryModalClose = () => {
    dispatch(setUpdatedDeliveryTime(false));
  };

  const getOrderConfirmation = async () => {
    if (!basket.order && inApp && search) {
      
      const orderId = query.get('ord');
      const restaurantId = query.get('rid');
      if (!orderId) return;
      await getOrder({ id: orderId, restaurantId: restaurantId });
    }
  };

  useEffect(() => {
    getOrderConfirmation();
  }, []);

  return (
    <>
      <Route path={`${path}/menu/:restaurantId?`}>
        <MenuController
          onAddItem={handleAddItem}
          onRemoveItem={handleRemoveItem}
          notification={notification}
          setNotification={setNotification}
          refreshBasket={refreshBasket}
        />
      </Route>
      <Route path={`${path}/checkout`}>
        <Route path={`${path}/checkout/details`}>
          {!hasCustomerDetails(basket)
            ? <Redirect to={`${path}/checkout/login`}/>
            : !basket?.items?.length && !basket?.bundles?.length
              ? <Redirect to={`${path}/menu/${restaurant?.id}`} />
              : <CheckoutController />}
        </Route>
        <Route path={`${path}/checkout`}>
          <CheckoutController onAddItem={handleQuickAddItemAndReward} />
        </Route>
      </Route>

      <Route path={`${path}/payment`}>
        {!basket?.items?.length && !basket?.bundles?.length
          ? !restaurant?.id
            ? <Redirect to="/" />
            : <Redirect to={`${path}/menu/${restaurant?.id}`} />
          : !hasCustomerDetails(basket, customer)
            ? existsButNeedsMobileNumber(customer)
              ? <Redirect to={`${path}/checkout/verifyMobile`} />
              : <Redirect to={`${path}/checkout/login`} />
            : !collectionTime && !basket.delivery ? <Redirect to={`${path}/checkout/details`} />
              : basket.delivery && !basket.delivery.deliveryAddress.addressLine1 ? <Redirect to={`${path}/checkout/details`} />
                : <PaymentController setDisablePaymentBackButton={setDisablePaymentBackButton} disablePaymentBackButton={disablePaymentBackButton} />}
      </Route>

      <Route path={`${path}/basket`}>
        {!restaurant?.id
          ? <Redirect to="/" />
          : <BasketController
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={false}
          />}
      </Route>
      <Route path={`${path}/loyalty`}>
        {!restaurant?.id
          ? <Redirect to="/" />
          : <BasketController
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={true}
          />}

      </Route>
      <Route exact path={`${path}/forgottenPassword`} component={ForgottenPassword} />
      <Route exact path={`${path}/passwordResetConfirmation`} component={PasswordResetConfirmation} />
      <Route exact path={`${path}/order/confirmation`}>
        {
          basket.order || data ? <OrderSummary
            isRouted={!!query.get('ord')}
            loyalty={loyalty}
            onChangeRestaurant={() => setShowChangeRestaurantModal(true)}
            order={basket.order || data}
            getOrder={getOrder}
          />
            : isOrderLoading || isFetching || (isUninitialized && search)
              ? <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
                <img src={LoaderBlack} style={{ width: 64, height: 64 }} />
              </div>
              : <Redirect to="/" />
        }
      </Route>
      <DeliveryTimeUpdateModalWrapper
        onClose={handleUpdatedDeliveryModalClose}
        show={pathname !== '/collection' && pathname !== '/delivery' && deliveryTimeUpdated}
        restaurantId={restaurant?.id}
        deliveryEstimate={basket?.delivery?.deliveryQuote?.duration}
        inApp={inApp}
      />
    </>
  );
};

export default OrderJourneyController;