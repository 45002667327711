import React from 'react';
import { useHistory } from 'react-router';
import minimize from '../../../img/icons/minus-outlined.svg';
import plus from '../../../img/icons/plus-outlined.svg';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import InvalidVoucher from '../../../components/modals/InvalidVoucher';
import { config } from '../../../config';
import { getCals } from '../../../helpers/basketHelpers';

const CustomiseHeader = (props) => {
  const {
    quantity,
    name,
    description,
    saveDisabled,
    setQuantity,
    customisedItem,
    screenWidth,
    isBundle,
    lastHeader,
    currentPrice,
    addToBasket,
    isOpen,
    inApp,
    item
  } = props;

  const history = useHistory();
  const isSmallScreen = useBreakPoint(screenWidth, 500);
  const isXSmallScreen = useBreakPoint(screenWidth, 367);
  const cals = getCals(item, customisedItem?.subProducts?.[0]?.subProducts?.[0].id);

  return (
    <>
      <div style={{ marginTop: lastHeader ? 16 : null }}>
        <div className="is-flex-direction-column">
          <div className="content is-flex-direction-column" style={{ marginBottom: description ? 24 : 0 }}>
            <div className="block is-flex is-justify-content-space-between">
              <h2 className="title customise-header-item-name" style={{ marginBottom: 0 }} >{name && name.replace(/NEW! /g, '').split('(')[0].toUpperCase()}</h2>
              {!isBundle && customisedItem?.dietPreferences && (<div className="level mt-2 is-mobile is-justify-content-center is-pulled-right">
                {customisedItem?.dietPreferences.map((option, idx) => (
                  <div key={option + idx} className="level-item is-narrow is-shrinkable">
                    <div className={`icon ${isSmallScreen ? 'is-small' : ''}`}>
                      <img className="w-100 h-100" src={require(`../../../img/icons/dietary/${option}.svg`)} />
                    </div>
                  </div>
                ))}
              </div>)}
            </div>
            <div className="block is-flex is-align-items-center" style={{ marginBottom: '32px' }} >
              <p className="m-0 mr-4 dm-sans" style={{ margin: '0 1rem 0 0' }}>{config.CURRENCY_SYMBOL}{(currentPrice * quantity).toFixed(2)}</p>
              {
                customisedItem && !isBundle && cals &&
                <span style={{ opacity: 0.8, fontSize: 18 }}>{`(${cals})`}</span>
              }
            </div>
            <div className={`is-flex is-justify-content-space-between ${isSmallScreen && 'is-flex-direction-column'} is-flex-wrap-wrap`} style={{ gap: isSmallScreen ? 32 : 24, marginBottom: 0 }}>

              <a className="is-flex is-size-6 is-align-items-center change-selection dm-sans" style={{ marginBottom: 0 }} onClick={() => history.goBack()}>Change selection</a>

              <div className={`is-flex ${isXSmallScreen && 'is-flex-direction-column-reverse-mobile'}  is-justify-content-space-between is-align-items-center`} style={{ gap: '24px' }}>
                <div className="is-flex is-align-items-center add-margin-top-ios" style={{ gap: '16px' }}>

                  <img src={minimize} className="icon is-medium" onClick={() => setQuantity(prev => prev > 1 ? prev - 1 : prev)} style={{ cursor: 'pointer' }} />
                  <p className="domain-display" style={{ margin: '0 0.5rem', fontSize: 20 }} >{quantity}</p>
                  <img src={plus} className="icon is-medium" onClick={() => setQuantity(prev => prev + 1)} style={{ cursor: 'pointer' }} />

                </div>
                {
                  !inApp &&
                  <button
                    disabled={saveDisabled}
                    onClick={addToBasket}
                    className="btn btn-primary"
                    style={{ width: 'auto', fontWeight: 500, height: 48 }}
                  >
                    {`Add ${isBundle ? 'deal ' : ''}to basket`}
                  </button>

                }
              </div>
            </div>
          </div>

          {description && (
            <div className="block">
              <div className="content">
                <p style={{ fontSize: 16 }}>{description}</p>
              </div>
            </div>

          )}
        </div>

        <InvalidVoucher isOpen={isOpen} />
      </div>
    </>
  );
};

export default CustomiseHeader;