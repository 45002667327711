import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../helpers/commonHelpers';
import { applicationApi } from '../services/application.api';
import { customerApi } from '../services/customer.api';

const cookiesAccepted = getCookie('cookie_accepted');
const inApp = getCookie('OOP_IN_APP');

const initialState = {
  loggedInUser: null,
  user: {},
  restaurantId: null,
  restaurant: {},
  checkoutCompletePath: '/',
  collectionTime: '',
  customerFacingOrderId: '',
  menuScrollPosition: 0,
  features: {
    deliveryLandingPage: false, // changes the home page to show delivery
    delivery: false, // Controlls if /delivery urls work and the journey is enabled
    loyalty: false, // All Loyalty UI show/hide
    showStackedRewards: false, //For stacked rewards
  },
  captcha: false,
  cookiesAccepted: cookiesAccepted ? cookiesAccepted : false,
  freshRelevanceInitialised: false,
  hasShownEmployeeModal: false,
  deliveryTimeUpdated: false,
  cmsConfig: {},
  inApp: !!inApp,
  hasSeenBasket: false,
  searchTerm: '',
  bannerHeight: 0,
  navMenuHeight: 0,
  searchResults: null,
  skipAppState: false,
  appInsets: false
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    clearUser(state) {
      state.user = {};
      state.collectionTime = '';
    },
    setRestaurant(state, action) {
      state.restaurantId = action.payload.id;
      if (action.payload.id !== null && action.payload.object !== null) {
        state.restaurant = action.payload.object;
      }
      Sentry.setTag('RestaurantId', state.restaurantId);
    },
    setRestaurantObject(state, action) {
      state.restaurant = action.payload;
    },
    setRestaurantId(state, action) {
      state.restaurantId = action.payload.id;
      Sentry.setTag('RestaurantId', state.restaurantId);
      state.restaurant = { ...state.restaurant, id: action.payload.id };
    },
    setSessionSearchResults(state, action) {
      state.searchResults = action.payload;
    },
    setCheckoutCompletePath(state, action) {
      state.checkoutCompletePath = action.payload;
    },
    resetCheckoutCompletePath(state) {
      if (state.restaurantId) {
        state.checkoutCompletePath = `/menu/${state.restaurantId}`;
      } else {
        state.checkoutCompletePath = '/';
      }
    },
    setCollectionTime(state, action) {
      state.collectionTime = action.payload;
    },
    setAddress(state, action){
      state.deliveryAddress = action.payload;
    },
    reloadBasket(state, { payload }) {
      state.restaurant = payload.restaurant;
      if (state.restaurant) {
        state.restaurantId = payload.restaurant.id;
        Sentry.setTag('RestaurantId', state.restaurantId);
      }
      state.user = payload.customer;
    },
    setMenuScrollPosition(state, { payload }) {
      state.menuScrollPosition = payload;
    },
    setDeliverySessionState(state, { payload }) {
      state.deliverySession = payload;
    },
    resetDeliverySession(state) {
      state.deliverySession = {};
    },
    initialiseFreshRelevance(state) {
      state.freshRelevanceInitialised = true;
    },
    setHasShownEmployeeModal(state, { payload }) {
      state.hasShownEmployeeModal = payload;
    },
    setUpdatedDeliveryTime(state, { payload }) {
      state.deliveryTimeUpdated = payload;
    },
    setHasSeenBasket(state, { payload }) {
      state.hasSeenBasket = payload;
    },
    setBannerHeight(state, { payload }) {
      state.bannerHeight = payload;
    },
    setNavMenuHeight(state, { payload }) {
      state.navMenuHeight = payload;
    },
    resetSessionSearchData(state) {
      state.searchResults = [];
      state.searchTerm = '';
      state.skipAppState = true;
    },
    setAppInsets(state, {payload}) {
      state.appInsets = payload;
    }
  },
  // Listens for API to be called else where and loads result into state
  extraReducers: builder => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (!state.skipAppState) {
          state.basketId = payload.basket?.basketId;
          state.restaurant = payload.restaurant;
          if (state.restaurant) {
            state.restaurantId = payload.restaurant.id;
            Sentry.setTag('RestaurantId', state.restaurantId);
          }
          state.customer = payload.customer;
          state.features = payload.features;
          state.cmsConfig = payload.cmsConfig;
          state.captcha = payload.captcha;
          if (payload.basket?.basket?.delivery) {
            state.deliveryTimeUpdated = payload.basket.basket.delivery.deliveryQuote.updated;
          }
          const newSearchTerm = payload?.basket?.basket?.searchTerm;
          if (newSearchTerm) {
            state.searchTerm = newSearchTerm;
          }
        }
        state.skipAppState = false;
      }
    );
    builder.addMatcher(
      customerApi.endpoints.registerUser.matchFulfilled,
      (state, { payload }) => {
        if (payload.status == 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.status == 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.validate2FA.matchFulfilled,
      (state, { payload }) => {
        if (payload.status == 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => initialState
    );
    builder.addMatcher(
      customerApi.endpoints.mobileUpdate.matchFulfilled,
      (state, { payload }) => {
        if (payload.status == 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.acceptCookies.matchFulfilled,
      (state) => {
        state.cookiesAccepted = true;
      }
    );
  }
});

export const {
  setSessionSearchTerm,
  clearUser,
  setRestaurant,
  setRestaurantObject,
  setRestaurantId,
  setCheckoutCompletePath,
  resetCheckoutCompletePath,
  setCollectionTime,
  setAddress,
  reloadBasket,
  setMenuScrollPosition,
  setDeliverySessionState,
  resetDeliverySession,
  initialiseFreshRelevance,
  setHasShownEmployeeModal,
  setUpdatedDeliveryTime,
  setHasSeenBasket,
  setBannerHeight,
  setNavMenuHeight,
  setSessionSearchResults,
  setSkipAppState,
  resetSessionSearchData,
  setAppInsets
} = sessionSlice.actions;
export default sessionSlice.reducer;