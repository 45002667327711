import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleHero from '../../../components/SimpleHero';
import DropDown from '../../../components/DropDown';
import DropDownField from '../../../components/DropDownField';
import { config } from '../../../config';
import { getDistanceText } from '../../../helpers/commonHelpers';
import timeIcon from '../../../img/icons/timeIcon.svg';
import { useLazyFindRestaurantByIdQuery, useLazyGetTimeSlotsQuery } from '../../../services/restaurant.api';
import { setCollectionTime } from '../../../store/sessionSlice';
import locationIcon from '../../../img/icons/location-new.svg';

export const createCollectionTimeOption = (timespan) => {
  const split = timespan.split(':');
  return {
    value: timespan,
    label: `${split[0]}:${split[1]}`
  };
};

const CollectionTime = ({
  fullWidth=false,
  hideTitle=false,
  error,
  setError,
  customClass
}) => {
  const restaurant = useSelector(state => state.session.restaurant);
  const {total, restaurantDistance} = useSelector(state => state.basket);
  const collectionTime = useSelector(state => state.session.collectionTime);
  const [times, setTimes] = useState([]);
  const [gotTimes, setGotTimes] = useState(false);
  const dispatch = useDispatch();
  const [getTimeSlotsQuery] = useLazyGetTimeSlotsQuery();
  const [findRestaurantById] = useLazyFindRestaurantByIdQuery();

  const updateCollectionTime = time => {
    dispatch(setCollectionTime(time));
    if (setError) {
      setError('');
    }
  };

  useEffect(() => {
    const refreshTimeSlots = async () => {
      const { data } = await getTimeSlotsQuery(restaurant.id);
      setGotTimes(true);
      setTimes(data);
    };
    refreshTimeSlots();

  }, []);

  useEffect(() => {
    const checkCollectionTime = async () => {
      if (!!times && times.length > 0 && collectionTime === '') {
        updateCollectionTime(times[0]);
        setError && setError('');
      } else if (!times || times.length === 0 && gotTimes) {
        const { data } = await findRestaurantById(restaurant.id);
        if (data.isOpen) {
          setError && setError('Oh no! There are no collection slots available at the moment, please try again later.');
        } else {
          setError && setError('Oh no! There are no collection times available at the moment, as the restaurant is now closed.');
        }
      } else if (!!times && times.length > 0) {
        setError && setError('');
      }
    };

    checkCollectionTime();
  }, [times, collectionTime]);

  const valueCallback = (_, value) => updateCollectionTime(value);

  const restaurantDistanceText = getDistanceText(restaurantDistance);

  return (
    <div className={`collection-time ${customClass ? customClass : ''} column ${fullWidth ? '' : 'is-6 is-offset-3'}`} style={{...fullWidth ? {paddingLeft: 0, paddingRight: 0} : {}}}>
      <div className="columns">
        <div className="column pt-0">
          <div className="content">
            {!hideTitle && (
              <SimpleHero title="collection time" customStyle={{paddingBottom: '1rem'}} customClass={'collection-time-title'}/>
            )}

            <div className="details-content-wrapper">   
              <div className="block">
                <DropDown>
                  <DropDownField
                    title='Select collection time'
                    name="time"
                    icon={timeIcon}
                    value={collectionTime}
                    valueCallback={valueCallback}
                    options={times?.map(t => createCollectionTimeOption(t)) || []}
                    id='collectionTimeDropDown'
                    titleExtraIdentifier={'Select collection time drop down'}
                    error={error}
                  />
                </DropDown>
              </div>
              <div className="block">
                <div className="location-icon-wrapper">
                  <i>
                    <img src={locationIcon} />
                  </i>
                  <div className="level-item is-justify-content-flex-start is-shrinkable">
                    <div className="block">
                      {restaurant?.name && (
                        <div className="restaurant-name is-capitalized">
                          {restaurant.name}
                        </div>
                      )}
                      {restaurantDistanceText && (
                        <div className="restaurant-distance">
                          {restaurantDistanceText}
                        </div>
                      )}
                      {restaurant?.fullAddress && (
                        <div className="restaurant-address">
                          {restaurant.fullAddress}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="total-wrapper">
                  <span className="total-label">Total</span>
                  <span className="total-value">{config.CURRENCY_SYMBOL}{total?.toFixed(2) || '0.00'}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionTime;
