import * as Sentry from '@sentry/react';
import React, { useState, useEffect } from 'react';
import PayByCard from './PayByCard';
import PayByVault from './PayByVault';
import PayByPaypal from './PayByPaypal';
import PayByApplePay from './PayByApplePay';
import PayByGooglePay from './PayByGooglePay';
import LoaderBlack from '../../../img/common/Loader_360x360.gif';
import { useDispatch, useSelector } from 'react-redux';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { historyMW } from '../../../helpers/routingHelpers';
import { useHistory } from 'react-router';
import { updateBasket } from '../../../store/basketSlice';

const Pay = (props) => {
  const [clientToken, setClientToken] = useState(null);
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const [googlePayEnabled, setGooglePayEnabled] = useState(true);
  const [paypalEnabled, setPaypalEnabled] = useState(true);
  const [googleEnvironment, setGoogleEnvironment] = useState(null);
  const [googleMerchantId, setGoogleMerchantId] = useState(null);
  const [paypalEnvironment, setPaypalEnvironment] = useState(null);
  const [newCard, setNewCard] = useState(false);
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const getBraintreeTokenAndCapabilities = async () => {
      if (window.ApplePaySession || props.supportedPaymentMethods?.applePay) {
        setCanUseApplePay(true);
      }
      try {
        const response = await props.getClientToken({ restaurantId: props.restaurantId, isDelivery, vaultId: props.customer?.vaultId });
        if (response.error?.data?.voucherExceptions?.length) {
          dispatch(updateBasket({ ...response.error?.data.basket, voucherExceptions: response.error?.data?.voucherExceptions }));
          throw new Error('Voucher mismatch');
        }
        const { clientToken, googlePayEnabled, paypalEnabled, googleEnvironment, googleMerchantId, paypalEnvironment } = response.data;
        setGooglePayEnabled(inApp ? props.supportedPaymentMethods?.googlePay : googlePayEnabled);
        setPaypalEnabled(paypalEnabled);
        setGoogleEnvironment(googleEnvironment);
        setGoogleMerchantId(googleMerchantId);
        setPaypalEnvironment(paypalEnvironment);
        setClientToken(clientToken);
      } catch (err) {
        Sentry.captureException(err);
        props.handleNoClientToken();
        historyMW.replace('/payment/error', isDelivery, history);
      }
    };

    getBraintreeTokenAndCapabilities();

    return () => {
      props.submitPaymentCancel();
    };
  }, [props.supportedPaymentMethods?.googlePay, inApp]);

  useEffect(() => {
    gtmEvent(isDelivery ? 'delivery_payment' : 'collection_payment', { channel: inApp ? 'App' : 'Web' });
    freshRelevance('pageChange', null, { 'sendBeacon': true });
  }, []);

  if (clientToken) {
    const paymentFailReason = props.paymentFailReason || 'Payment failed, please try again.';
    return (
      <div className="pay-by-container content">

        {props.paymentFailed === true && <p className="payment-failure-message">{paymentFailReason}</p>}

        {(paypalEnabled || canUseApplePay || googlePayEnabled) ? (
          <div className='express-checkout'>
            <p style={{ margin: 0 }}>How would you like to pay?</p>
            <div style={{ display: 'flex', gap: 16, justifyContent: 'center', minHeight: 48, flexWrap: 'wrap', alignContent: 'center' }}>
              {paypalEnabled && (
                <div id="payByPaypalDiv" style={{ width: 200, maxWidth: 200 }}>
                  <PayByPaypal environment={paypalEnvironment} clientToken={clientToken} {...props} inApp={inApp} />
                </div>
              )}
              {canUseApplePay && (
                <div id="payByApplePayDiv" style={{ width: 200, maxWidth: 200 }}>
                  <PayByApplePay clientToken={clientToken} {...props} inApp={inApp} />
                </div>
              )}
              {googlePayEnabled &&
                <div style={{ width: 200, maxWidth: 200 }}>
                  <PayByGooglePay id="payByGooglePay" environment={googleEnvironment} merchantId={googleMerchantId} clientToken={clientToken} {...props} inApp={inApp} />
                </div>
              }
            </div>
            <p className='payment-or'>or</p>
          </div>
        ) : null}

        <h3 className="title pay-by-card">PAY BY CARD</h3>

        {!newCard ? (
          <PayByVault id="payByVault" clientToken={clientToken} {...props} inApp={inApp} toggleNewCard={() => setNewCard(!newCard)} />
        ) : (
          <PayByCard id="payByCard" clientToken={clientToken} {...props} inApp={inApp}/>
        )}
      </div>
    );
  } else {
    return (
      <div id='loadingDiv'>
        <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '1.5rem 0' }}>
          <img src={LoaderBlack} style={{ width: 50 }} />
          <br />
          Loading Checkout...</p>
      </div>
    );
  }
};

export default Pay;
