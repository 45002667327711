import React from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import VerifyMobile from './pages/VerifyMobile';
import Details from './pages/Details';
import { useDispatch, useSelector } from 'react-redux';
import UpdateDetails from './pages/UpdateDetails';
import VerifyEmail from './pages/VerifyEmail';
import { historyMW } from '../../helpers/routingHelpers';
import { setCheckoutCompletePath } from '../../store/sessionSlice';

const CheckoutController = ({ onAddItem }) => {
  const { path } = useRouteMatch();
  const { isDelivery } = useSelector(state => state.basket);
  const loyalty = useSelector(state => state.session?.features.loyalty);
  const history = useHistory();
  const dispatch = useDispatch();
  const onContinue = (user, isGuest = false, redirectUrl) => {
    if (isGuest) historyMW.replace('/payment', isDelivery, history);
    else if (!user.mobileNumber) historyMW.replace('/checkout/verifyMobile', isDelivery, history);
    else if (loyalty && !user.appApproved) historyMW.replace('/checkout/verifyemail', isDelivery, history);
    else if (loyalty && user.appApproved) historyMW.replace('/loyalty', isDelivery, history);
    else if (redirectUrl && redirectUrl !== '/') {
      dispatch(setCheckoutCompletePath('/'));
      history.replace(redirectUrl);
    }
    else historyMW.replace('/checkout/details', isDelivery, history);
  };
  const navigateTo = (path, state = {}) => {
    historyMW.push(path, isDelivery, history, state);
  };


  const onContinueFromVerifyEmail = () => {
    historyMW.replace('/checkout/details/joined', isDelivery, history);
  };

  const onContinueFromLogin = (user, redirectUrl) => {
    if (!user.mobileNumber) historyMW.replace('/checkout/verifyMobile', isDelivery, history);
    else if (redirectUrl && redirectUrl !== '/') {
      dispatch(setCheckoutCompletePath('/'));
      history.replace(redirectUrl);
    }
    else if (loyalty && user.appApproved) historyMW.replace('/loyalty', isDelivery, history);
    else historyMW.replace('/checkout/details', isDelivery, history);
  };

  return (
    <Switch>
      <Route exact path={`${path}/login`}>
        <Login onContinue={onContinueFromLogin}/>
      </Route>
      <Route exact path={`${path}/guest`}>
        <SignUp guest={true} onContinue={onContinue} />
      </Route>
      <Route exact path={`${path}/join/:fromBanner?`} >
        <SignUp guest={false} onContinue={onContinue} />
      </Route>
      {<Route exact path={`${path}/verifyEmail/:restaurantId?/:email?/:code?`}>
        <VerifyEmail onContinue={onContinueFromVerifyEmail} />
      </Route>}
      <Route exact path={`${path}/verifyMobile`}>
        <VerifyMobile onContinue={onContinue} />
      </Route>
      <Route exact path={`${path}/details/:joined?`}>
        <Details onAddItem={onAddItem} navigateTo={navigateTo}/>
      </Route>
      <Route exact path={`${path}/updateDetails`} component={UpdateDetails} />
    </Switch>
  );
};

export default CheckoutController;
