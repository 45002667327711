import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Payment from './pages/Payment';
import Retry from './pages/Retry';
import { historyMW } from '../../helpers/routingHelpers';
import { useLazyGetOrderForTodayQuery } from '../../services/order.api';

const PaymentController = ({setDisablePaymentBackButton, disablePaymentBackButton}) => {
  const { customer, restaurantId } = useSelector(state => state.session);
  const { isDelivery, total, optIn } = useSelector(state => state.basket);
  const [paymentTaken, setPaymentTaken] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();
  const [getOrderForToday] = useLazyGetOrderForTodayQuery();
  const [showSpinner, setShowSpinnner] = useState(false);

  let getIdTimeout;

  useEffect(() => {
    document?.body?.scrollTo({ top: 0 });
  }, [showSpinner]);

  const handleCompletePayment = useCallback(async ({ id }) => {
    setShowSpinnner(true);
    setDisablePaymentBackButton(true);
    getIdTimeout = setInterval(async () => {
      const { data } = await getOrderForToday({ restaurantId, id });
      if (data.status === 'PENDING' || data.status === 'SUCCESS' || data.status === 'OUT_OF_STOCK') {
        clearInterval(getIdTimeout);
        setErrorStatus(data.status);
        setDisablePaymentBackButton(false);
        historyMW.replace('/order/confirmation', isDelivery, history);
      } else if (data.status === 'REJECTED') {
        clearInterval(getIdTimeout);
        setErrorStatus(data.status);
        setDisablePaymentBackButton(false);
        setPaymentErrorMessage('Unfortunately Uber can no longer fulfil this order. If payment has been taken, we have refunded you. Please try again or try Collection instead.');
        historyMW.replace('/payment/error', isDelivery, history);
      } else if (data.status === 'FAILED' || data.status === 'VOID') {
        clearInterval(getIdTimeout);
        setErrorStatus(data.status);
        setPaymentErrorMessage('Oh no! Something went wrong and we have been unable to process your order. You will be issued a full refund. We’re so sorry about that, please try placing an order again later or contact the restaurant directly.');
        setDisablePaymentBackButton(false);
        historyMW.replace('/payment/error', isDelivery, history);
      } else if (data.status === 'PROCESSING' && !paymentTaken) {
        setErrorStatus(data.status);
        setPaymentTaken(true);
      }
    }, 2000);
  }, []);

  useEffect(() => {
    return () => {
      if (getIdTimeout) {
        clearInterval(getIdTimeout);
      }
    };
  }, []);

  useEffect(() => {
    const removeFiles = () => {
      document.querySelectorAll('script[src*="www.paypal.com/sdk"]').forEach(node => node.remove());
      const frames = document.querySelectorAll('iframe');
      for (var i = 0; i < frames.length; i++) {
        frames[i].parentNode.removeChild(frames[i]);
      }
      const scripts = document.querySelectorAll('script');
      for (var j = 0; j < scripts.length; j++) {
        if (scripts[j].src.includes('pay.google.com')) {
          scripts[j].parentNode.removeChild(scripts[j]);
        }
      }
    };

    removeFiles();
    const script = document.createElement('script');
    script.src = 'https://pay.google.com/gp/p/js/pay.js';
    script.async = true;
    document.body.appendChild(script);

    return () => removeFiles();
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}/error`}>
        <Retry
          paymentErrorMessage={paymentErrorMessage}
          setShowSpinnner={setShowSpinnner}
          setDisablePaymentBackButton={setDisablePaymentBackButton}
          setPaymentTaken={setPaymentTaken}
          errorStatus={errorStatus}
        />
      </Route>
      <Route exact path={path}>
        <Payment
          showSpinner={showSpinner}
          handleCompletePayment={handleCompletePayment}
          grandTotal={total.toFixed(2)}
          customer={customer}
          setPaymentErrorMessage={setPaymentErrorMessage}
          setShowSpinnner={setShowSpinnner}
          setDisablePaymentBackButton={setDisablePaymentBackButton}
          optIn={optIn}
          paymentTaken={paymentTaken}
          setPaymentTaken={setPaymentTaken}
          setErrorStatus={setErrorStatus}
          disablePaymentBackButton={disablePaymentBackButton} />
      </Route>
    </Switch>
  );
};

export default PaymentController;
