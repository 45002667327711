import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from '../../../helpers/commonHelpers';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import { setMenuScrollPosition } from '../../../store/sessionSlice';
import RewardController from '../../../components/loyalty/RewardController';
import { usePrefetch } from '../../../services/restaurant.api';
import FilterMenuButton from '../../../components/FilterButton';
import chevronLeftWhite from '../../../img/icons/ChevronLeftWhite.svg';
import chevronRightWhite from '../../../img/icons/ChevronRightWhite.svg';
const MenuHeader = ({ menu, selectedCategory, setSelectedCategory, setDietModalIsOpen, filters, inApp }) => {
  const [categoryRef, setCategoryRef] = useState();
  const [widthCategory, setWidthCategory] = useState(2000);
  const { restaurantId, customer } = useSelector(state => state.session);
  const isSmallScreenCategory = useBreakPoint(categoryRef?.offsetWidth, widthCategory, true, [widthCategory]);
  const menuPosition = useSelector(state => state.session.menuScrollPosition);
  const { navMenuHeight } = useSelector(state => state.session);
  const dispatch = useDispatch();
  const appApproved = useSelector(state => state.session?.customer?.appApproved);
  const { loyalty } = useSelector(state => state.session.features);
  const intervalRef = useRef();
  const prefetchCategory = usePrefetch('getMenuCategory');
  const setNewMenuPosition = (position) => {
    dispatch(setMenuScrollPosition(position));
  };
  const throttledSetMenuPosition = throttle(setNewMenuPosition, 200);

  const scrollListenerCategory = (e) => {
    e.preventDefault();
    const newScroll = categoryRef.scrollLeft + e.deltaY / 2;
    categoryRef.scrollLeft = newScroll;
    throttledSetMenuPosition(newScroll);
  };

  const addScrollListenerCategory = () => {
    categoryRef.addEventListener('wheel', scrollListenerCategory);
  };

  const removeScrollListenerCategory = () => {
    categoryRef.removeEventListener('wheel', scrollListenerCategory);
  };

  useEffect(() => {
    let menuWidth = 0;
    menu.forEach(item => menuWidth += 20 + (item.length * 12));
    setWidthCategory(menuWidth);
  }, [menu]);

  useEffect(() => {
    if (categoryRef && menuPosition !== 0) {
      categoryRef.scrollLeft = menuPosition;
    }
  }, [categoryRef]);

  const hasLoginBanner = customer === null && loyalty;
  const handleScrollStart = (up = true) => {
    if (!categoryRef) return;
    categoryRef.scrollTo({ top: 0, left: categoryRef.scrollLeft += up ? 100 : -100, behavior: 'smooth' });
    intervalRef.current = setInterval(() => {
      categoryRef.scrollTo({ top: 0, left: categoryRef.scrollLeft += up ? (categoryRef.offsetWidth / menu.length) : -(categoryRef.offsetWidth / menu.length), behavior: 'smooth' });
    }, 75);
  };

  const handleScrollEnd = () => {
    clearInterval(intervalRef.current);
  }

  return (
    <>
      <div className={`container is-max-widescreen menu-header-container ${hasLoginBanner ? 'has-banner' : ''} ${inApp ? 'in-app' : ''}`} style={{ position: 'sticky', zIndex: 300, top: navMenuHeight }}>
        <section id='menu-categories' className="section menu-header is-scroller">
          <FilterMenuButton setDietModalIsOpen={setDietModalIsOpen} filters={filters} />
          <div
            className='container px-0-mobile px-0-tablet'
            style={{
              width: '100%',
              marginTop: '0.5rem', // required to avoid clash with Filter button
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <div className='menu-header-scroll-button-grid'>
              {isSmallScreenCategory && menu?.length &&
                <img
                  src={chevronLeftWhite}
                  className='menu-category-scroll-button'
                  onPointerDown={() => handleScrollStart(false)}
                  onPointerUp={handleScrollEnd}
                  onPointerLeave={() => clearInterval(intervalRef?.current)}
                />
              }
              <div
                ref={(ref) => setCategoryRef(ref)}
                className={`menu-nav-item-wrapper px-4-tablet-only scrollbar-slim ${isSmallScreenCategory ? 'small-screen' : ''}`}
                onMouseEnter={addScrollListenerCategory}
                onMouseLeave={removeScrollListenerCategory}
                onScroll={e => {
                  categoryRef.scrollLeft = e.target.scrollLeft;
                  throttledSetMenuPosition(e.target.scrollLeft);
                }}
              >
                {menu?.map((category, idx) => (
                  <div
                    key={category}
                    className={`menu-nav-item ${category === selectedCategory ? 'active' : ''}`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    <h2
                      key={`category-${idx}`}
                      onMouseEnter={() => prefetchCategory({ id: restaurantId, name: category }, { ifOlderThan: 300 })}
                    >
                      {category}
                    </h2>
                  </div>
                ))}
              </div>
              {isSmallScreenCategory && menu?.length &&
                <img
                  src={chevronRightWhite}
                  className='menu-category-scroll-button'
                  onPointerDown={handleScrollStart}
                  onPointerUp={handleScrollEnd}
                  onPointerLeave={() => clearInterval(intervalRef?.current)}
                />
              }
            </div>
          </div>
        </section>
      </div>

      {loyalty && appApproved &&
        <div className="container">
          <RewardController inApp={inApp} />
        </div>}
    </>
  );
};

export default MenuHeader;
