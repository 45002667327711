import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import braintree from 'braintree-web';
import visa from '../img/visa.svg';
import mastercard from '../img/mastercard.svg';
import maestro from '../img/maestro.svg';
import americanExpress from '../img/american-express.svg';
import checkSelected from '../../../img/icons/checked.svg';
import checkNotSelected from '../../../img/icons/checkboxempty.svg';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config';

const PayByVault = ({
  clientToken,
  submitPaymentFail,
  paymentSuccessful,
  generatePaymentBody,
  grandTotal,
  currentlySubmittingPayment,
  setCurrentlySubmittingPayment,
  toggleNewCard,
  customer,
  pay,
  setDisablePaymentBackButton,
  inApp
}) => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const { cmsConfig } = useSelector(state => state.session);  

  useEffect(() => {
    const renderDropIn = async () => {
      const vaultManagerInstance = await braintree.vaultManager.create({
        authorization: clientToken,
      });
      vaultManagerInstance.fetchPaymentMethods({ defaultFirst: true }, (err, paymentMethods) => {
        setPaymentMethods(paymentMethods);
        if (!paymentMethods || paymentMethods.length === 0) {
          toggleNewCard(true);
        }
      });
    };

    renderDropIn();
  }, []);


  const postPayment = async (payload) => {
    try {
      setIsPaying(true);
      const response = await pay(generatePaymentBody(payload));
      const data = response.data || response.error?.data;
      if (data?.status !== 'OK') {
        Sentry.captureException(data);
        submitPaymentFail(data);
        setCurrentlySubmittingPayment(false);
        return;
      }
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      paymentSuccessful(data);
    }
    finally {
      setIsPaying(false);
    }
  };

  const payWithPayload = async () => {
    gtmEvent('cardpay_click', { channel: inApp ? 'App' : 'Web' });
    setCurrentlySubmittingPayment(true);
    setDisablePaymentBackButton(true);

    var threeDSecureParameters = {
      amount: grandTotal,
      nonce: selectedCard.nonce,
      bin: selectedCard.details.bin,
      email: customer?.customerSessionToken?.emailAddress,
      challengeRequested: cmsConfig?.Enable3DSChallengeRequested ?? true,
      onLookupComplete: (data, next) => {
        // use `data` here, then call `next()`
        next();
      }
    };

    try {
      let threeDSecure;

      const clientInstance = await braintree.client.create({ authorization: clientToken });

      threeDSecure = await braintree.threeDSecure.create({ version: '2', client: clientInstance });

      const payByCardContainer = document.getElementById('vaulted-cards');

      threeDSecure.on('authentication-iframe-available', (event, next) => {
        payByCardContainer.appendChild(event.element);
        event.element.className = 'three-d-secure-container';
        next();
      });

      try {
        let response = await threeDSecure.verifyCard(threeDSecureParameters);
        threeDSecure.teardown();
        var threeDSecureElem = document.getElementsByClassName('three-d-secure-container')[0];
        if (threeDSecureElem) {
          payByCardContainer.removeChild(threeDSecureElem);
        }
        postPayment(response);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        threeDSecure.teardown();
        var threeDSecureElem2 = document.getElementsByClassName('three-d-secure-container')[0];
        if (threeDSecureElem2) {
          payByCardContainer.removeChild(threeDSecureElem2);
        }
        submitPaymentFail();
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      submitPaymentFail();
      setCurrentlySubmittingPayment(false);
    }
  };

  const vaultedCard = ({ paymentMethod, selected, cardType, lastFour, expires, preferred, expired }) => {
    return (
      <label
        onClick={() => !isPaying && !expired ? setSelectedCard(paymentMethod) : null}
        className="checkbox vaulted-card">
        <img style={{ alignSelf: 'flex-start' }} className="check" src={selected ? checkSelected : checkNotSelected} alt="checked" />
        <div style={{ width: '100%', fontWeight: 400, textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <p style={{ fontSize: 16, marginBottom: 2 }}>{cardType} ending in {lastFour}</p>
          <p style={{ fontSize: 12, marginBottom: 0 }}>Expires {expires}</p>
          {preferred &&
            <span
              style={{
                fontWeight: 400,
                backgroundColor: '#1C1A1A',
                color: 'white',
                padding: '8px 12px',
                borderRadius: 32,
                marginTop: 12,
                fontSize: 12
              }}
            >
              Preferred
            </span>
          }
        </div>
        <img style={{ width: 50 }} src={(cardType === 'Visa' ? visa : (cardType === 'MasterCard' ? mastercard : (cardType === 'Maestro' ? maestro : americanExpress)))} />
      </label>
    );
  };

  const paymentMethodsRenderer =
    paymentMethods &&
    paymentMethods.map((paymentMethod, index) => (
      <div className="vaulted-card-container" key={index}>
        <div key={`c ${index}`}>
          {vaultedCard({
            paymentMethod: paymentMethod,
            selected: selectedCard && paymentMethod.nonce === selectedCard.nonce,
            cardType: paymentMethod.details.cardType,
            lastFour: paymentMethod.details.lastFour,
            expires: `${paymentMethod.details.expirationMonth}/${`${paymentMethod.details.expirationYear}`.slice(-2)}`,
            preferred: index === 0,
            // cards remain valid until the last calendar day of that month, expirationMonth jan = 1, getMonth jan = 0
            expired: parseInt(paymentMethod.details.expirationYear) < new Date().getFullYear() ||
              parseInt(paymentMethod.details.expirationYear) === new Date().getFullYear() && paymentMethod.details.expirationMonth - 1 < new Date().getMonth()
          })}
        </div>
      </div>
    ));

  return (
    <div className="vaulted-cards">
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {paymentMethodsRenderer}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: 16, marginTop: 8 }}>
        <button style={{ color: 'black' }} onClick={toggleNewCard} className="btn btn-secondary" disabled={isPaying}>Add a new card</button>
        <button disabled={currentlySubmittingPayment || !selectedCard} id='payButton' className="btn btn-primary" onClick={payWithPayload}>Pay {config.CURRENCY_SYMBOL}{grandTotal}</button>
      </div>
    </div>
  );
};

export default PayByVault;