import React, { useState, useCallback, useRef } from 'react';
import SimpleHero from '../../../components/SimpleHero';
import InputField from '../../../components/InputField';
import DropDown from '../../../components/DropDown';
import DropDownField from '../../../components/DropDownField';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import { useUpdateCustomerMutation, useUpdateGuestDetailsMutation } from '../../../services/customer.api';
import { useSelector } from 'react-redux';
import { historyMW } from '../../../helpers/routingHelpers';

const UpdateDetails = () => {
  const { title, firstName, lastName, postCode, mobileNumber, dateOfBirth, email } = useSelector(state => state.basket);
  const { isDelivery } = useSelector(state => state.basket);
  const { customer, inApp } = useSelector(state => state.session);
  const [inputValues, setInputValues] = useState({
    title: title,
    firstName: firstName,
    lastName: lastName,
    mobileNumber: mobileNumber,
    postcode: postCode,
    dob: dateOfBirth,
    emailAddress: email,
  });
  const [inputErrors, setInputErrors] = useState({
    title: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    postcode: '',
  });


  const [updateGuestDetails, { isLoading: isUpdatingGuestDetails }] = useUpdateGuestDetailsMutation();
  const [updateCustomer, { isLoading: isUpdatingCustomer }] = useUpdateCustomerMutation();

  const history = useHistory();
  const titleRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const postcodeRef = useRef(null);

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const scrollToError = (titleError, firstNameError, lastNameError, mobileNumberError, postcodeError) => {
    if (titleError !== '') titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (firstNameError !== '') firstNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (lastNameError !== '') lastNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (mobileNumberError !== '') mobileNumberRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (postcodeError !== '') postcodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const continueToPayment = async () => {
    let titleError = '', firstNameError = '', lastNameError = '', mobileNumberError = '', postcodeError = '';

    if (inputValues.title === '') titleError = 'Please enter your title';
    if (inputValues.firstName === '') firstNameError = 'Please enter your first name';
    if (inputValues.lastName === '') lastNameError = 'Please enter your last name';
    if (inputValues.mobileNumber === '') mobileNumberError = 'Please enter your mobile number';
    if (inputValues.postcode === '') postcodeError = 'Please enter your postcode';

    if (inputErrors.title) titleError = 'Please enter a valid title';
    if (inputErrors.firstName) firstNameError = 'Please enter a valid first name';
    if (inputErrors.lastName) lastNameError = 'Please enter a valid last name';
    if (inputErrors.mobileNumber) mobileNumberError = 'Please enter a valid mobile number';
    if (inputErrors.postcode) postcodeError = 'Please enter a valid postcode';

    if (titleError || firstNameError || lastNameError || mobileNumberError || postcodeError) {
      setInputErrors({
        title: titleError,
        firstName: firstNameError,
        lastName: lastNameError,
        mobileNumber: mobileNumberError,
        postcode: postcodeError,
      });
      scrollToError(titleError, firstNameError, lastNameError, mobileNumberError, postcodeError);
    } else {
      if (customer) await updateCustomer({ ...inputValues, email: inputValues.emailAddress });
      else await updateGuestDetails({ ...inputValues });
      historyMW.replace('/checkout/details', isDelivery, history);
    }
  };

  return (
    <Layout
      title={'Your contact details'}
      isFluid={true}
      backgroundColour={inApp ? 'malbec-100' : 'transparent'}
      titleColour={inApp ? 'rose-100' : 'black'}
      section={'is-spaced'}
      isBasket
      isSmallerTitle
      customStyles='is-basket'
      backButton={() => history.goBack()}
    >
      <div className={`details-content-wrapper ${inApp ? 'in-app' : ''}`}>
        <div className="content">

          {inputValues.title !== null && (<div className="mb-5" ref={titleRef}>
            <DropDown>
              <DropDownField
                title='Title'
                name='title'
                value={inputValues.title}
                valueCallback={handleChange}
                error={inputErrors.title}
                errorCallback={handleError}
                required={true}
                options={[
                  { value: 'Miss', label: 'Miss' },
                  { value: 'Mr', label: 'Mr' },
                  { value: 'Mrs', label: 'Mrs' },
                  { value: 'Ms', label: 'Ms' },
                  { value: 'Dr', label: 'Dr' }
                ]}
                titleExtraIdentifier={'Select your title'}
                placeHolder='Please select'
                id='titleDrowDown'
              />
            </DropDown>
          </div>
          )}
          <div className="mb-5" ref={firstNameRef}>
            <InputField
              title="First name"
              name='firstName'
              value={inputValues.firstName}
              valueCallback={handleChange}
              error={inputErrors.firstName}
              errorCallback={handleError}
              valueType="text"
              required={true}
              id='firstNameInput'
            />
          </div>
          <div className="mb-5" ref={lastNameRef}>
            <InputField
              title="Last name"
              name='lastName'
              value={inputValues.lastName}
              valueCallback={handleChange}
              error={inputErrors.lastName}
              errorCallback={handleError}
              valueType="text"
              required={true}
              id='lastNameInput'
            />
          </div>
          <div className="mb-5" ref={mobileNumberRef}>
            <InputField
              title="Mobile number"
              name='mobileNumber'
              value={inputValues.mobileNumber}
              valueCallback={handleChange}
              error={inputErrors.mobileNumber}
              errorCallback={handleError}
              valueType="tel"
              type="tel"
              required={true}
              maxLength="11"
              id='mobileNumberInput'
            />
          </div>
          <div className="mb-5" ref={postcodeRef}>
            <InputField
              title="Postcode"
              name='postcode'
              value={inputValues.postcode}
              valueCallback={handleChange}
              error={inputErrors.postcode}
              errorCallback={handleError}
              valueType="postcode"
              required={true}
              id='postcodeInput'
            />
          </div>

          <div className="has-text-centered">
            <button
              id='continueButton'
              onClick={continueToPayment}
              className="btn btn-primary w10"
              style={{ margin: 'auto' }}
              disabled={isUpdatingGuestDetails || isUpdatingCustomer}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateDetails;
