import React from 'react';
import minusIcon from './../img/icons/round-light-minus.svg';
import plusIcon from './../img/icons/round-dark-plus.svg';
import { getCals, getDefaultPortion } from '../helpers/basketHelpers';
import { config } from '../config';
import DietaryIcons from './DietaryIcons';

const UpsellModalCard = ({
  id,
  modalItem,
  screenWidth,
  item,
  item: {
    name,
    imageUrl,
    description,
    displayPrice,
    dietPreferences },
  quantity,
  onChange,
  inApp }) => {

  const handleChange = (e, mode) => {
    e.stopPropagation();
    onChange(item, mode);
  };

  const defaultPortion = getDefaultPortion(item);
  
  const figureStyle = inApp ? { display: 'flex', justifyContent: 'center', heigh:'6rem' } : {};
  const cardTitleStyle = inApp ? { fontFamily: 'Domaine-display-bold', textAlign: 'start' } : {};
  const quantityStyle = inApp ? { display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' } : {};
  const quantityTextStyle = inApp ? { fontFamily: "Domaine-display-bold", fontSize: "1.75rem", lineHeight: '2rem' } : {};
  const backgroundStyle = inApp ? {
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative'
  } : {
    backgroundImage: 'url(' + imageUrl + ')',
    position: 'absolute',
    width: '100%',
    height: '100%'
  };
  const iconStyle = inApp ? {
    cursor: 'pointer',
    height: '2.25rem',
    width: '2.25rem'
  } : {
    cursor: 'pointer'
  };
  return (
    <>
      <div
        key={id}
        className={`upsell-modal-card m-0 h100 ${modalItem ? 'auto-cursor' : ''}`}
        onClick={(e) => handleChange(e, true)}
        style={{
          marginTop: screenWidth < 769 ? 0 : '',
          marginBottom: screenWidth < 769 ? 0 : '',
          flexGrow: inApp && 1,
          display: inApp &&  'flex',
          flexDirection: 'column',
        }}
      >
        <div className="upsell-modal-card-content">
          <figure className="image is-3by1-desktop" style={figureStyle}>
            <img src={imageUrl} className="background-image" style={backgroundStyle}>
            </img>
          </figure>
        </div>
        <div className="upsell-modal-card-content info">
          <div className={`block mb-0-desktop`} style={{ boxSizing: 'content-box', marginBottom: inApp && '0rem' }}>
            <div className="block mb-0" >
              <div className={`card-title is-size-5 is-prewrap ${inApp && '.is-size-6'}`} style={cardTitleStyle}>
                {dietPreferences && !inApp && <div className="level is-mobile is-justify-content-center is-pulled-right">
                  <DietaryIcons icons={dietPreferences} />
                </div>}
                {name}
              </div>
              {
                !modalItem &&
                <p style={{ whiteSpace: 'normal' }}>{description}</p>
              }
              {
                defaultPortion &&
                <div className='is-prewrap' style={{ marginBottom: 4 }}>{defaultPortion.name}</div>
              }
            </div>
          </div>
          {
            modalItem &&
            <div className="is-flex is-flex-direction-column is-align-items-center">
              {
                modalItem &&
                <div className="is-flex is-justify-content-space-between w100"
                  style={inApp ? { flexDirection: 'column', alignItems: 'flex-start !important', lineHeight: 'unset', textAlign: 'start', marginBottom: '1rem', gap: 8 } : {alignItems: 'center', marginBottom: '0.5rem', flexWrap: 'wrap', gap: '8px 20px'}}
                >
                  <p className="price is-size-6" style={{fontFamily: "Domaine-display"}}>{config.CURRENCY_SYMBOL}{displayPrice.toFixed(2)}</p>
                  <p className="calories is-size-7" >{getCals(item, null, true)}</p>
                  {inApp && 
                    <DietaryIcons icons={dietPreferences} />
                  }
                </div>
              }
              <div className='upsell-modal-quantity' style={quantityStyle}>
                <img src={minusIcon} className="icon change-quantity" data-testid='decrease' style={iconStyle} onClick={(e) => handleChange(e, false)} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p data-testid='quantity' className='quantity-value' style={quantityTextStyle}>
                    {quantity}
                  </p>
                </div>
                <img src={plusIcon} style={iconStyle} className="icon change-quantity" onClick={(e) => handleChange(e, true)} data-testid='increase' />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default UpsellModalCard;
