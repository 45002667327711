import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router';
import HomeController from './features/home/HomeController';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import { useLazyGetApplicationStateQuery } from './services/application.api';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIFrameModalWrapper, CollectionTimeErrorModalWrapper, DeliveryErrorModalWrapper, NotificationModalWrapper, TemporarilyClosedModalWrapper } from './helpers/modalHelpers';
import OutOfStockModal from './components/modals/OutOfStockModal';
import ResetPassword from './features/checkout/pages/ResetPassword';
import Loading from './components/Loading';
import { historyMW } from './helpers/routingHelpers';
import OrderJourneyController from './OrderJourneyController';
import MenuRedirect from './features/menu/components/MenuRedirect';
import CookiesBanner from './components/CookiesBanner';
import { useLogoutMutation, useChangeRestaurantMutation } from './services/customer.api';
import { initialiseFreshRelevance, setAppInsets, setSessionSearchTerm } from './store/sessionSlice';
import { useSendToHome } from './hooks/useSendToHome';
import { CompareRewards } from './components/loyalty/CompareRewards';
import { getCookie, gtmEvent } from './helpers/commonHelpers';
import { setHasSeenVoucherExceptionModal } from './store/basketSlice';
import { useClearBasketDeliveryMutation, useClearBasketMutation } from './services/basket.api';
import { checkCanSaveBasket } from './helpers/basketHelpers';
import { postMessageWithAwait } from './helpers/appHelpers';

let timeout = 0;

export const hasCustomerDetails = (basket, customer) =>
  basket?.email && customer == null || (customer != null && customer.mobileNumber);

export const existsButNeedsMobileNumber = (customer) => (customer != null && !customer.mobileNumber);

const AppController = () => {
  const [getApplicationState, { isLoading: isGettingApplicationState, isFetching }] = useLazyGetApplicationStateQuery();
  const [logout] = useLogoutMutation();
  const [changeRestaurant] = useChangeRestaurantMutation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [height, setHeight] = useState(0);
  const restaurantIdMatchDelivery = useRouteMatch('/delivery/menu/:restaurantId');
  const restaurantIdMatchCollection = useRouteMatch('/collection/menu/:restaurantId');
  const { loyalty } = useSelector(state => state.session.features);
  const { menu } = useSelector(state => state.menu);
  const { restaurant, collectionTime, customer, cookiesAccepted, features, inApp, hasSeenBasket, cmsConfig, searchTerm, appInsets } = useSelector(state => state.session);
  const { isDelivery, delivery, voucherExceptions, hasSeenVoucherExceptionModal, order, numberOfItems, id } = useSelector(state => state.basket);
  const [showChangeRestaurantModal, setShowChangeRestaurantModal] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [showCloseIframeModal, setShowCloseIframeModal] = useState(false);
  const [clearBasket] = useClearBasketMutation();
  const sendToHome = useSendToHome();
  window.initialiseFreshRelevance = () => dispatch(initialiseFreshRelevance());
  const [showNotification, setShowNotification] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [disablePaymentBackButton, setDisablePaymentBackButton] = useState(false);
  const [clearBasketDelivery] = useClearBasketDeliveryMutation();
  const offHomePage = pathname !== '/delivery' && pathname !== '/collection';
  const { basket } = useSelector(state => state);

  const outOfStockItems = [
    ...basket?.outOfStockItems || [],
    ...basket?.unavailableItems || []
  ];
  const isBasketDelivery = useRouteMatch('/delivery/basket');
  const isBasketCollection = useRouteMatch('/collection/basket');

  const [outOfStockModalOpen, setOutOfStockModalOpen] = useState(false);
  const [prevPathname, setPrevPathname] = useState('/');
  const routeRestaurantId = restaurantIdMatchDelivery?.params?.restaurantId || restaurantIdMatchCollection?.params?.restaurantId;

  useEffect(() => {
    const isOnSearchScreen = pathname === '/collection';
    const isOnDeliverySearchScreen = pathname === '/delivery';
    const isOnOrderOnlineScreen = pathname === '/';
    if (outOfStockItems.length > 0 && !isBasketDelivery && !isBasketCollection && !isOnSearchScreen && !isOnOrderOnlineScreen && !isOnDeliverySearchScreen) {
      setOutOfStockModalOpen(outOfStockItems.length > 0 && !isBasketDelivery && !isBasketCollection && !isOnSearchScreen && !isOnOrderOnlineScreen && !isOnDeliverySearchScreen);
    }
  }, [outOfStockItems.length, isBasketDelivery, isBasketCollection, pathname]);

  const [didomiObject, setDidomiObject] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      await getApplicationState(routeRestaurantId);
      setHasInitialized(true);
      setHideLoader(true);
    };
    if (!restaurant?.id || (routeRestaurantId && restaurant?.id !== routeRestaurantId) || pathname !== prevPathname && pathname !== '/delivery' && pathname !== '/collection') {
      setPrevPathname(pathname);
      getData();
    }
  }, [routeRestaurantId, restaurant?.id, pathname]);

  const handleChangeRestaurant = async () => {
    gtmEvent(isDelivery ? 'Delivery_Change_Restaurant' : 'Collection_Change_Restaurant', { channel: inApp ? 'App' : 'Web' });
    await changeRestaurant();
    sendToHome();
  };

  const refreshBasket = () => {
    gtmEvent(isDelivery ? 'Delivery_Refresh_Basket' : 'Collection_Refresh_Basket', { channel: inApp ? 'App' : 'Web' });
    if (timeout) {
      clearTimeout(timeout);
      timeout = 0;
    }
    timeout = setTimeout(() => {
      getApplicationState(routeRestaurantId);
    }, 5000);
  };

  const handleRemovedItems = async () => {
    gtmEvent(isDelivery ? 'Delivery_Remove_Items' : 'Collection_Remove_Items', { channel: inApp ? 'App' : 'Web' });
    historyMW.replace('/basket', isDelivery, history);
    setOutOfStockModalOpen(false);
    refreshBasket();
  };

  const handleLogOut = async () => {
    gtmEvent(isDelivery ? 'Delivery_Logout' : 'Collection_Logout', { channel: inApp ? 'App' : 'Web' });
    await logout();
    sendToHome();
    await getApplicationState(routeRestaurantId);
  };

  const showLoginBanner = customer === null && (restaurantIdMatchDelivery?.isExact || restaurantIdMatchCollection?.isExact) && loyalty;

  useEffect(() => {
    if (!hasSeenVoucherExceptionModal && voucherExceptions?.length) setShowNotification({
      heading: 'We’re Sorry!',
      description: voucherExceptions?.length > 1 ?
        `Vouchers have been removed from your basket as they are no longer valid./n${voucherExceptions.map(v => `*${v.code}*/n`).join('')}` :
        `A voucher has been removed from your basket as it is no longer valid/n*${voucherExceptions[0].code}*`,
      redirectFn: async () => dispatch(setHasSeenVoucherExceptionModal()),
      redirectCta: 'Continue'
    });
  }, [hasSeenVoucherExceptionModal && voucherExceptions]);

  const getSaveBasket = () => {
    if (order || restaurant === null) return null;
    else return {
      journey: isDelivery ? 'delivery' : 'collection',
      journeyStep: hasSeenBasket && numberOfItems > 0 ? 'basket' : 'menu',
      basketId: id,
      restaurantId: restaurant?.id,
      restaurantClosingTime: restaurant?.closingTime
    };
  };

  const closeIframe = async (dontSaveBasket) => {
    if (dontSaveBasket) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'CLOSE_OOP' }));
      return;
    }
    const data = {
      type: 'CLOSE_OOP',
      basket: getSaveBasket()
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(data));
  };

  useEffect(() => {
    if (inApp) {
      const defaultLog = console.log;
      const defaultError = console.error;
      console.log = (...args) => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOG', data: args }));
        defaultLog(...args);
      }
      console.error = (...args) => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ERROR', data: args }));
        defaultError(...args);
      }
      const bannerLinks = cmsConfig?.banners?.map(b => b.ctaLink)?.filter(url => !url.includes('order.pizzaexpress') || !url.includes(window.location.hostname)) ?? [];
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'EXTERNAL_LINKS',
          data: [
            ...bannerLinks,
            'www.pizzaexpress.com/allergens-and-nutritionals',
            'www.pizzaexpress.com/help-and-contact',
            'www.pizzaexpress.com/about-us/cookie-policy',
            'www.pizzaexpress.com/terms-of-use',
            'www.pizzaexpress.com/privacy-policy',
            'www.pizzaexpress.com/terms-and-conditions/online-ordering'
          ]
        })
      );
    }
  }, [cmsConfig?.banners]);

  useEffect(() => {
    if (!isGettingApplicationState && hideLoader) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'HIDE_LOADER' }));
      }
    }
  }, [isGettingApplicationState, hideLoader, hasInitialized]);

  const showLeaveOOPModal = async (url) => {
    setShowNotification({
      heading: 'Are you sure?',
      description: 'Leaving this page will result in you losing the contents of your basket.',
      redirectFn: async () => {
        await clearBasket();
        window.location = url;
      },
      redirectCta: 'Leave',
      cancel: 'Stay'
    });
  };

  useEffect(() => {
    const handleAppMessage = async (m) => {
      try {
        if (m.data) {
          const data = JSON.parse(m.data);
          if (data.type === 'SWITCH_JOURNEY') {
            if (data.journey === 'collection') {
              await clearBasketDelivery();
            }
            dispatch(setSessionSearchTerm(''));
            historyMW.push(`?postcode=${searchTerm}`, data.journey === 'delivery', history);
          }
        }
      } catch (err) {
        return;
      }
    };

    if (inApp) {
      window.addEventListener('message', handleAppMessage);
      document.addEventListener('message', handleAppMessage);

      window.didomiEventListeners = window.didomiEventListeners || [];
      window.didomiEventListeners.push({
        event: 'consent.changed',
        listener: () => {
          var cookieValue = getCookie('euconsent-v2');
          window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'EUCONSENT', value: cookieValue }));
        }
      });
    }

    const getInsets = async () => {
      try {
        const { insets } = await postMessageWithAwait({ type: 'OOP_INITIALISED' }, ['INSETS']);
        dispatch(setAppInsets(insets));
      } catch (err) {
        console.error('No insets returned from app')
      }
    }
    getInsets();

    return () => {
      window.removeEventListener('message', handleAppMessage);
      document.removeEventListener('message', handleAppMessage);
    }
  }, []);

  return (
    <>
      {
        !isGettingApplicationState && hasInitialized &&
        <div className={`wrapper navigation-margin ${showLoginBanner ? 'has-login-banner' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '100vw', flex: 1 }} >
          <NavMenu
            showLoginBanner={showLoginBanner}
            height={height}
            setHeight={setHeight}
            onLogOut={handleLogOut}
            inApp={inApp}
            offHomePage={offHomePage}
            handleCloseIframe={() => numberOfItems > 0 && order === null && checkCanSaveBasket(restaurant?.closingTime) ? setShowCloseIframeModal(true) : closeIframe(true)}
            leaveOOPHandler={showLeaveOOPModal}
            disablePaymentBackButton={disablePaymentBackButton}
          />
          <Route path='/' >
            <HomeController
              showChangeRestaurantModal={showChangeRestaurantModal}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
            />
          </Route>
          <Route path='/menu/:restaurantId' component={MenuRedirect} />
          <Route path='/collection'>
            <OrderJourneyController
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
              refreshBasket={refreshBasket} hasInitialized={hasInitialized}
              setDisablePaymentBackButton={setDisablePaymentBackButton}
              disablePaymentBackButton={disablePaymentBackButton} />
          </Route>
          <Route path='/delivery'>
            <OrderJourneyController
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
              refreshBasket={refreshBasket} hasInitialized={hasInitialized}
              setDisablePaymentBackButton={setDisablePaymentBackButton}
              disablePaymentBackButton={disablePaymentBackButton} />
          </Route>
          <Route path='/resetPassword/:state/:token' component={ResetPassword} />
          {
            features?.showRewardComparePage
              ? <Route path='/test/comparerewards' component={CompareRewards} />
              : null
          }
        </div>
      }
      {!inApp && !isGettingApplicationState && <Footer isDelivery={isDelivery} didomiObject={didomiObject} />}
      <Loading show={isGettingApplicationState} inApp={inApp} />

      <OutOfStockModal
        show={outOfStockModalOpen}
        inApp={inApp}
        onRemovedItems={handleRemovedItems}
        basket={basket}
        outOfStockItems={outOfStockItems}
      />
      <CollectionTimeErrorModalWrapper inApp={inApp} show={collectionTime === 'passed'} />
      <DeliveryErrorModalWrapper inApp={inApp} show={(isDelivery && delivery && delivery?.deliveryQuote?.error) ?? false} message={delivery?.deliveryQuote?.error} onClose={handleChangeRestaurant} />
      {hasInitialized &&
        <TemporarilyClosedModalWrapper
          restaurant={restaurant}
          isDelivery={isDelivery}
          show={true}
          inApp={inApp}
          changeWelcomeModalIsOpen={!isFetching && (pathname !== '/' && pathname !== '/collection' && pathname !== '/delivery') && ((restaurant && menu?.length === 0) || (restaurant?.isCollectionDisabled && !isDelivery) || (restaurant?.isDeliveryDisabled && isDelivery))} />
      }
      <CookiesBanner visible={!cookiesAccepted} inApp={inApp} didomiObject={didomiObject} setDidomiObject={setDidomiObject} />
      <NotificationModalWrapper
        show={showNotification}
        onClose={() => setShowNotification(false)}
        notificationState={showNotification}
        inApp={inApp}
      />
      <CloseIFrameModalWrapper
        onClose={() => setShowCloseIframeModal(false)}
        show={showCloseIframeModal}
        redirect={() => historyMW.push('', isDelivery || pathname.includes('/delivery'), history)}
        closeIframe={closeIframe}
        inApp={inApp}
      />
    </>
  );
};

export default AppController;